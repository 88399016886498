import { Text } from "@chakra-ui/react";
import { format, parseISO, isAfter } from "date-fns";

export const GoLiveDate = ({ site, ...props }) => {
  if (!site) {
    return null;
  }

  const goLiveDate = site.extraAttributes?.goLiveDate;

  if (!goLiveDate) {
    return null;
  }

  const date = parseISO(goLiveDate);

  if (isAfter(new Date(), date)) {
    return null;
  }

  let dateText;

  if (Intl.DateTimeFormat) {
    const dtf = new Intl.DateTimeFormat("en", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
    });

    dateText = dtf.format(date);
  } else {
    dateText = `${format(date, "EEE, MMM do, hh:mm a")} (your time)`;
  }

  return (
    <Text
      color="warning"
      fontSize="sm"
      {...props}
      data-testid="go-live-date"
      suppressHydrationWarning={true}
    >
      Launch: {dateText}
    </Text>
  );
};
